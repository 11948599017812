import React, { useEffect } from "react"
import cx from "classnames"
import ScrollReveal from "scrollreveal"
import loadable from "@loadable/component"
import { Link } from "gatsby"

import "../../assets/styles/commons/lists/_common__list__techLogos-rounded.scss"
import "../../assets/styles/commons/_common__headline__stairsDown.scss"
import "../../assets/styles/commonsGlossy.scss"
import * as styles from "../../assets/styles/dedicated-team.module.scss"
import { IMG } from "../../const"
import HeaderImages from "./HeaderImages"
import HireBlock from "./HireBlock"
import LeadingBlock from "./LeadingBlock"
import {
  HIRE_BLOCK_COMMON_TEXT,
  TECHNOLOGIES_FRONTEND,
  TECHNOLOGIES_BACKEND,
} from "./const"

const CaseStudies = loadable(() => import("../case-studies"))
const TechnologyContainer = loadable(() => import("./TechnologyContainer.js"))
const ContactsForm = loadable(() => import("../contact-form/contacts"))

const CommonDedicatedTeam = ({ type, screenWidth }) => {
  useEffect(() => {
    ScrollReveal().reveal(`#FrontendTechnologies li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 50,
      delay: 50,
    })
    ScrollReveal().reveal(`#BackendTechnologies li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 50,
      delay: 50,
    })
    ScrollReveal().reveal(`#TrustedBy li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 100,
      delay: 100,
    })
  }, [])

  const reactPageTitle = "World‑Class React Developers"

  return (
    <>
      <main className={cx(styles.dedicatedTeamBlock, "glossy")}>
        <article>
          <div className={cx(styles.dedicatedTeamBlock__headline, "upTo2cols")}>
            <div className="common__headline__stairsDown">
              <h1>
                {type === "React JS/Native"
                  ? reactPageTitle
                  : `Dedicated ${type} Developers`}
              </h1>
              <p>
                <mark>
                  Trustpicked talented, experienced {type} developers are ready
                  to work and support your system and implement new or ongoing
                  projects.
                </mark>
              </p>
              <p>
                We use a structured and tested approach to build professional,
                long-term {type} developers teams or offer single {type}{" "}
                developers for our partners.
              </p>
              <p>
                <Link to="#contact-form" className="goldFatLink">
                  Let’s talk
                </Link>
              </p>
            </div>
            <HeaderImages />
          </div>
          <section
            className={cx(
              styles.dedicatedTeamBlock__accompany,
              "commonGlossy__article__section upTo2cols"
            )}
          >
            <h2 className="at640__pr50p">
              We accompany you&nbsp;along&nbsp;the&nbsp;way
            </h2>
            <div>
              <p
                className={cx(
                  styles.dedicatedTeamBlock__accompany__leadQuote,
                  "common__leadQuote"
                )}
              >
                <strong>
                  LaSoft dedicated {type} developers service helps our clients
                  find fast ideal {type} developers for different time
                  cooperation. So we hire, train, and organize top talent and
                  offer our engineering services worldwide.
                </strong>
              </p>
            </div>
            <div>
              <p className={styles.dedicatedTeamBlock__accompany__motto}>
                <mark>
                  Due to almost 10 years of experience providing IT services
                  worldwide, from the US to Saudi Arabia, and transparent,
                  sustainable processes, we understand how important our
                  customers are to work with professional {type} developers
                  teams that perform well.
                </mark>
              </p>
              <p
                className={styles.dedicatedTeamBlock__accompany__strongEmphasis}
              >
                <em>
                  <strong>
                    A dedicated {type} developers' service model works better
                    for someone looking for staff augmentation by talented
                    professionals for planned or ongoing projects.
                  </strong>
                </em>
              </p>
              <p>
                <Link to="#contact-form" className="goldFatLink">
                  Let’s talk
                </Link>
              </p>
            </div>
          </section>
          <HireBlock
            title={` How to hire a ${type} Developer with LaSoft?`}
            text={`We use a structured and tested approach to build professional, long-term ${type} developer teams or for single hiring.`}
            options={HIRE_BLOCK_COMMON_TEXT}
          />
          <section
            className={cx(
              styles.dedicatedTeamBlock__trustedBy,
              "commonGlossy__article__section"
            )}
            id="TrustedBy"
          >
            <h2>We are trusted by</h2>
            <ul>
              <li>
                <img
                  src={`${IMG.ABOUT}/logo-CalvinKlein.svg`}
                  alt="Calvin Klein"
                  width="138"
                  height="48"
                  loading="lazy"
                />
              </li>
              <li>
                <img
                  src={`${IMG.ABOUT}/logo-sOliver.svg`}
                  alt="s.Oliver"
                  width="138"
                  height="48"
                  loading="lazy"
                />
              </li>
              <li>
                <img
                  src={`${IMG.ABOUT}/logo-HugoBoss.svg`}
                  alt="Hugo Boss"
                  width="138"
                  height="48"
                  loading="lazy"
                />
              </li>
            </ul>
          </section>
          <section
            className={cx(
              styles.dedicatedTeamBlock__talent,
              "commonGlossy__article__section"
            )}
          >
            <h2>LaSoft Talent</h2>
            <p className="at768__w50p">
              We use a structured and tested approach to build a professional,
              long-term dedicated team for our partners.
            </p>
            <ul className={styles.listIconic}>
              <li className={styles.icon__tool}>
                <h4>
                  Get a decent experience in building world-class projects
                </h4>
                <p>
                  We create teams of professional developers building complex
                  software for IT solutions worldwide. More than that, we
                  constantly improve employees' professional skills with the
                  help of mentorship, a peer-to-peer approach, and our own IT
                  academy.
                </p>
              </li>
              <li className={styles.icon__minimize}>
                <h4>Well-screened</h4>
                <p>
                  A decent approach to picking top candidates allows us to hire
                  the best candidates on the market. Engineers pass a 3‑level
                  screening process to get hired, where our experts check their
                  interpersonal, communication, English, and technical skills.
                </p>
              </li>
              <li className={styles.icon__mic}>
                <h4>We speak good English</h4>
                <p>
                  We take pride not only in the high level of technology
                  languages. All our employees have good English skills and
                  communicate with clients directly daily. We support the level
                  with company-based English language courses free of charge for
                  our employees.
                </p>
              </li>
              <li className={styles.icon__globe}>
                <h4>
                  Get to know standard software development approaches and tools
                </h4>
                <p>
                  Working with international clients for nearly 10&nbsp;years,
                  we have gained excellent experience using modern software
                  tools and processes such as scrum and agile development. The
                  adaptation process of our team members usually takes little
                  time.
                </p>
              </li>
              <li className={styles.icon__infinity}>
                <h4>Stay with a company long‑term</h4>
                <p>
                  On average, employees stay in the company for 3&nbsp;years. We
                  are proud to say that our staff turnover is very low.
                </p>
              </li>
            </ul>
          </section>
          <section
            className={cx(
              styles.dedicatedTeamBlock__techStack,
              "commonGlossy__article__section"
            )}
          >
            <h2>Technologies We Use</h2>
            <p>
              We use a structured and tested approach to build a professional,
              long-term dedicated team for our partners.
            </p>
            <div className="upTo2cols">
              <div>
                <h5>Frontend</h5>
                <ul
                  className="common__list__techLogos-rounded"
                  id="FrontendTechnologies"
                >
                  {TECHNOLOGIES_FRONTEND.map(technology => (
                    <TechnologyContainer
                      technology={technology}
                      key={technology.id}
                    >
                      <figure>
                        <img
                          src={`${IMG.ICONS}/logos/${technology.icon}`}
                          width="32"
                          height="32"
                          loading="lazy"
                          alt={technology.id}
                        />
                        <figcaption>{technology.name}</figcaption>
                      </figure>
                    </TechnologyContainer>
                  ))}
                </ul>
                <h5>Backend</h5>
                <p>
                  Can be chosen in accordance with your business needs
                  or&nbsp;preferences
                </p>
                <ul
                  className="common__list__techLogos-rounded"
                  id="BackendTechnologies"
                >
                  {TECHNOLOGIES_BACKEND.map(technology => (
                    <TechnologyContainer
                      technology={technology}
                      key={technology.id}
                    >
                      <figure>
                        <img
                          src={`${IMG.ICONS}/logos/${technology.icon}`}
                          width="32"
                          height="32"
                          loading="lazy"
                          alt={technology.id}
                        />
                        <figcaption>{technology.name}</figcaption>
                      </figure>
                    </TechnologyContainer>
                  ))}
                </ul>
              </div>
              <div className={styles.dedicatedTeamBlock__techStack__pic}>
                {/*
                    Images in AVIF format do not give an acceptable ratio of quality and weight,
                    so in this case, we do not use them -- karlsson
                */}
                <picture className={styles.dedicatedTeamBlock__techStack__pic1}>
                  <source
                    srcSet={`${IMG.DEDICATED_TEAM}/code/1.webp,	${IMG.DEDICATED_TEAM}/code/1@2x.webp 2x,	${IMG.DEDICATED_TEAM}/code/1@3x.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    srcSet={`${IMG.DEDICATED_TEAM}/code/1.jpg,	${IMG.DEDICATED_TEAM}/code/1@2x.jpg 2x,		${IMG.DEDICATED_TEAM}/code/1@3x.jpg 3x`}
                  />
                  <img
                    src={`${IMG.DEDICATED_TEAM}/code/1@3x.jpg`}
                    alt="React page code"
                    width="310"
                    height="372"
                    loading="lazy"
                  />
                </picture>
                <picture className={styles.dedicatedTeamBlock__techStack__pic2}>
                  <source
                    srcSet={`${IMG.DEDICATED_TEAM}/code/2.webp,	${IMG.DEDICATED_TEAM}/code/2@2x.webp 2x,	${IMG.DEDICATED_TEAM}/code/2@3x.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    srcSet={`${IMG.DEDICATED_TEAM}/code/2.jpg,	${IMG.DEDICATED_TEAM}/code/2@2x.jpg 2x,		${IMG.DEDICATED_TEAM}/code/2@3x.jpg 3x`}
                  />
                  <img
                    src={`${IMG.DEDICATED_TEAM}/code/2@3x.jpg`}
                    alt="React helmet code"
                    width="315"
                    height="330"
                    loading="lazy"
                  />
                </picture>
              </div>
            </div>
          </section>

          <div className="commonGlossy__article__case-studies">
            <CaseStudies title="" screenWidth={screenWidth} />
          </div>

          <LeadingBlock />
        </article>
      </main>
      <section className="footerForm">
        <div id="contact-form">
          <ContactsForm formStatID="CommonFooter" />
        </div>
      </section>
    </>
  )
}

export default CommonDedicatedTeam
